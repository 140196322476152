exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-project-project-handle-js": () => import("./../../../src/pages/projects/[project.projectHandle].js" /* webpackChunkName: "component---src-pages-projects-project-project-handle-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */),
  "slice---src-components-structure-footer-jsx": () => import("./../../../src/components/structure/footer.jsx" /* webpackChunkName: "slice---src-components-structure-footer-jsx" */),
  "slice---src-components-structure-header-jsx": () => import("./../../../src/components/structure/header.jsx" /* webpackChunkName: "slice---src-components-structure-header-jsx" */)
}

